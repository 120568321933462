import { useNavigate } from '@remix-run/react';
import { $path } from 'remix-routes';

import { EditIcon } from '../../../icons/EditIcon';
import { ActionsMenu } from '../Components/ActionMenu';

interface CourseStack {
  id: string;
  name: string;
  learnerCount: number;
}

interface CourseStacksProps {
  stacks: CourseStack[];
}

export const CourseStacksSection = ({ stacks }: CourseStacksProps) => {
  const navigate = useNavigate();

  const getStackActions = (stack: CourseStack) => [
    {
      label: 'Edit Stack',
      icon: <EditIcon className='w-6 h-6 fill-current text-white pr-2' />,
      onClick: () =>
        navigate(
          $path('/learning/admin/my-courses/stacks/:id', { id: stack.id })
        ),
    },
  ];

  return (
    <>
      <h2 className='text-xl md:text-2xl font-bold text-white mb-4 md:mb-6 hidden md:block'>
        Part of Stacks ({stacks.length})
      </h2>

      {stacks.length === 0 ? (
        <div className='bg-[#17171A] border border-lp-gray-003 rounded-lg p-6 text-center text-gray-400 mt-2'>
          <div className='mb-2'>This course is not part of any stacks yet</div>
          <div className='text-sm'>
            Add this course to stacks to organize related courses together
          </div>
        </div>
      ) : (
        <>
          {/* Header row */}
          <div className='hidden md:flex w-full px-4 py-2 items-center text-gray-400 text-xs uppercase font-medium'>
            <div className='w-1/3'>Stack Name</div>
            <div className='w-1/3'>Learners</div>
            <div className='w-12'></div>
          </div>

          {/* Stack rows */}
          <div className='flex flex-col gap-2.5 mt-2'>
            {stacks.map((stack) => (
              <div
                key={stack.id}
                className='
                  relative w-full
                  rounded-lg px-4 py-3
                  bg-[#17171A] hover:bg-[#1f1f22] transition-colors
                  flex flex-col md:flex-row md:items-center
                  border-lp-gray-003 border hover:cursor-pointer
                '
                onClick={() =>
                  navigate(
                    $path('/learning/admin/my-courses/stacks/:id', {
                      id: stack.id,
                    })
                  )
                }
              >
                <div className='md:w-1/3 text-white font-medium text-sm mb-1 md:mb-0'>
                  {stack.name}
                </div>
                <div className='md:w-1/3 text-white/90 text-sm mb-2 md:mb-0'>
                  {stack.learnerCount}{' '}
                  {stack.learnerCount === 1 ? 'learner' : 'learners'}
                </div>
                {/* Action menu col */}
                <div className='md:w-1/3 relative flex items-center justify-end w-12 self-end md:self-auto'>
                  <ActionsMenu
                    actions={getStackActions(stack)}
                    className='w-50 text-left'
                  />
                </div>
              </div>
            ))}
          </div>
        </>
      )}
    </>
  );
};
