import {
  type ClientLoaderFunctionArgs,
  type ShouldRevalidateFunction,
  useLoaderData,
} from '@remix-run/react';

import { CourseDetailsPage } from '../components/Training/Admin/Course/CourseDetail';
import { apiService } from '../services/api-service';
import { tokenWithRedirect } from '../utils/router';

export async function clientLoader(action: ClientLoaderFunctionArgs) {
  const { id } = action.params;
  if (!id) throw new Response('Not Found', { status: 404 });

  const [pack, groups, stacks] = await tokenWithRedirect(
    () =>
      Promise.all([
        apiService.gamePack.getGamePackById(id, {
          blocks: true,
          games: true,
        }),
        apiService.learning.getCourseAssignedGroups(id),
        apiService.learning.getCourseStacks(id),
      ]),
    action.request.url
  );

  return {
    pack: pack.data,
    groups: groups.data.groups,
    stacks: stacks.data.stacks,
    games: pack.data.games,
  };
}

export function Component() {
  const { pack, groups, stacks, games } = useLoaderData<typeof clientLoader>();

  return (
    <CourseDetailsPage
      pack={pack.gamePack}
      groups={groups}
      stacks={stacks}
      games={games}
    />
  );
}

export const shouldRevalidate: ShouldRevalidateFunction = ({
  currentUrl,
  nextUrl,
  defaultShouldRevalidate,
}) => {
  const currentParams = new URLSearchParams(currentUrl.search);
  const nextParams = new URLSearchParams(nextUrl.search);

  // Check if only sort and dir parameters changed
  const currentSortField = currentParams.get('sort');
  const currentSortDir = currentParams.get('dir');
  const nextSortField = nextParams.get('sort');
  const nextSortDir = nextParams.get('dir');

  if (currentSortField !== nextSortField || currentSortDir !== nextSortDir) {
    // If other parameters are the same, we don't need to revalidate
    const currentParamsWithoutSort = new URLSearchParams(currentUrl.search);
    const nextParamsWithoutSort = new URLSearchParams(nextUrl.search);

    currentParamsWithoutSort.delete('sort');
    currentParamsWithoutSort.delete('dir');
    nextParamsWithoutSort.delete('sort');
    nextParamsWithoutSort.delete('dir');

    if (
      currentParamsWithoutSort.toString() === nextParamsWithoutSort.toString()
    ) {
      return false;
    }
  }

  return defaultShouldRevalidate;
};
